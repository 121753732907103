var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(_vm.ghsComponent, {
        tag: "component",
        attrs: { param: _vm.popupParam },
        on: {
          "update:param": function ($event) {
            _vm.popupParam = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }